import React from "react"
import P from "../../core/P"
import H2 from "../../core/H2"
import AddsForm from "../Forms/AddsForm"

const BannerContactForm = ({
    title,
    subtitle,
    backgroundCover,
    ctaButton,
    ctaButton2,
    index,
    theme,
}) => {
    
    return (
        <div
            className={`w-full bg-cover bg-center min-h-screen overflow-hidden`}
            style={{
                backgroundImage: `url(${backgroundCover?.mediaItemUrl})`,
                backgroundColor: "bg-shark-500",
            }}
        >
            <div className="w-full flex lg:flex-row flex-col items-center bg-opacity-75 bg-shark-500">
                <div className="lg:w-1/2 w-full flex justify-center lg:px-6 px-8">
                    <div className="flex justify-center flex-col lg:pt-0 pt-12 mx-auto">
                        <div className="flex lg:justify-start justify-center lg:text-left text-center">
                            {title && (
                                <H2
                                    title={title}
                                    className={`text-white  max-w-5xl mx-auto`}
                                    textColor="#ffffff"
                                    index={index}
                                />
                            )}
                        </div>
                        <div className="flex lg:justify-start justify-center lg:text-left text-center">
                            {subtitle && <P noCenter title={subtitle} className={`text-white`} />}
                        </div>
                        <div className="flex lg:justify-start justify-center lg:text-left text-center">
                            {ctaButton && (
                                <a
                                    href={ctaButton.url}
                                    className="mt-5 md:mt-8 bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                                // className="mt-5 py-3 hover:bg-blue-500 focus:bg-blue-600 px-8 bg-transparent border-white hover:border-transparent focus:border-transparent border rounded text-white"
                                >
                                    {ctaButton?.title}
                                </a>
                            )}
                            {ctaButton2 && (
                                <a
                                    href={ctaButton2?.url}
                                    className="ml-5 mt-5 md:mt-8 bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                                // className="mt-5 py-3 hover:bg-blue-500 focus:bg-blue-600 px-8 bg-transparent border-white hover:border-transparent focus:border-transparent border rounded text-white"
                                >
                                    {ctaButton2?.title}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                {/* Contact Us Form */}
                <div className="lg:w-1/2 w-full">
                    <div className="lg:h-screen h-auto lg:my-0 md:my-24 my-16 flex justify-center items-center py-auto lg:mx-10 mx-0">
                       <AddsForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerContactForm
